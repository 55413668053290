import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  CssBaseline,
  Container,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../components/Copyright";
import { UsersDataContext } from "../context/UsersDataContext";
import { Timestamp } from "@firebase/firestore";
import { cleanErrorMessage } from "../helpers/ErrorMessage";

const theme = createTheme();

const Signup = () => {
  const [error, setError] = useState(null);
  const { createAuthUser } = UserAuth();
  const navigate = useNavigate();
  const { createUser } = useContext(UsersDataContext);

  const handleSubmit = async (e) => {
    setError(null);
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    try {
      await createNewUser(data, createAuthUser).then(() => {
        navigate("/");
      });
    } catch (e) {
      let errMessage = e.message;
      if (e.code === "auth/weak-password") {
        errMessage = errMessage.replace("auth/weak-password", "");
        errMessage = `Error ${errMessage}`;
      }
      setError(cleanErrorMessage(errMessage));
    }
  };

  const createNewUser = async (data, createAuthUser) => {
    let userId = "";
    if (data.get("firstName").trim() === "") {
      throw new Error("Missing First Name.");
    }
    if (data.get("lastName").trim() === "") {
      throw new Error("Missing Last Name.");
    }
    if (data.get("phone").trim() === "") {
      throw new Error("Missing Phone Number.");
    }
    await createAuthUser(data.get("email"), data.get("password"))
      .then(function (user) {
        userId = user.user.uid;
      })
      .catch(function (error) {
        throw error;
      });

    createUser({
      userId: userId,
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      phoneNumber: data.get("phone"),
      emailAddress: data.get("email"),
      updatedDtm: Timestamp.now(),
      createdDtm: Timestamp.now(),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Lake of Dreams Retreats
          </Typography>
          <Typography variate="h5" align="right">
            Account Sign Up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              {/* first name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>

              {/* last name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>

              {/* phone */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                />
              </Grid>

              {/* email */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Typography variant="h6" align="center" sx={{ mt: 3 }}>
              You must be 18 years of age or older to continue.
            </Typography>

            <Typography variate="h5" align="right" color="red">
              {error}
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              // onClick={handleSubmit}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};

export default Signup;
