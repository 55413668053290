import useGeneralizedCrudMethods from "./useGeneralizedCrudMethods";

const useUsersData = () => {
  // console.log("using useUsersData");
  const collectionName = "users";
  const errorNotificationFn = (error) => {
    console.log("Error From useUsersData", error);
  };

  const {
    data,
    error,
    loadingStatus,
    createRecord,
    updateRecord,
    deleteRecord,
    reFetch,
  } = useGeneralizedCrudMethods(collectionName, errorNotificationFn);

  function createUser(rec, callbackDone) {
    createRecord(collectionName, rec, callbackDone);
  }

  function updateUser(rec, callbackDone) {
    updateRecord(collectionName, rec, callbackDone);
  }

  function deleteUser(id, callbackDone) {
    deleteRecord(collectionName, id, callbackDone);
  }

  return {
    usersList: data,
    usersLoadingStatus: loadingStatus,
    error,
    createUser,
    updateUser,
    deleteUser,
    reFetch,
  };
};

export default useUsersData;
