export const cleanErrorMessage = function (rawMessage) {
  const filterOutFire = "Firebase: ";
  const filterOutAuth = "auth/";
  let cleanMsg = rawMessage.replace(filterOutFire, "");
  cleanMsg = cleanMsg.replace("Error", "Error:");
  cleanMsg = cleanMsg.replace(filterOutAuth, "");
  cleanMsg = cleanMsg.replace("(", "");
  cleanMsg = cleanMsg.replace(")", "");

  return cleanMsg;
};
