import useGeneralizedCrudMethods from "./useGeneralizedCrudMethods";

const useEventsData = () => {
  // console.log("using useEventsData");
  const collectionName = "events";
  const errorNotificationFn = (error) => {
    console.log("Error From useEventsData", error);
  };

  const {
    data,
    error,
    loadingStatus,
    createRecord,
    updateRecord,
    deleteRecord,
    reFetch,
  } = useGeneralizedCrudMethods(collectionName, errorNotificationFn);

  function createEvent(rec, callbackDone) {
    createRecord(collectionName, rec, callbackDone);
  }

  function updateEvent(id, rec, callbackDone) {
    updateRecord(collectionName, id, rec, callbackDone);
  }

  function deleteEvent(id, callbackDone) {
    deleteRecord(collectionName, id, callbackDone);
  }

  return {
    eventsList: data,
    eventsLoadingStatus: loadingStatus,
    error,
    createEvent,
    updateEvent,
    deleteEvent,
    reFetch,
  };
};

export default useEventsData;
