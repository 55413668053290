import { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
} from "firebase/firestore";

const LOADING_STATES = ["loading", "errored", "success"];

const useGeneralizedCrudMethods = (collectionName, errorNotificationFn) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loadingStatus, setLoadingStatus] = useState("loading");

  if (!collectionName) {
    throw new Error("useGeneralizedCrudMethods 'no query passed' error");
  }

  function formatErrorString(e, errorSummary) {
    const errorString =
      e?.response?.status === 404
        ? e?.message + "  " + errorSummary
        : e?.message + "  " + e?.response?.data;
    console.log(errorString);
    return errorString;
  }

  async function getData(callbackDone) {
    try {
      setLoadingStatus(LOADING_STATES[0]);
      // console.log(`${collectionName} - ${LOADING_STATES[0]}`);
      const q = query(collection(db, collectionName));
      const querySnapshot = await getDocs(q);
      let dataArr = [];
      querySnapshot.forEach((doc) => {
        dataArr.push({ ...doc.data(), id: doc.id });
      });
      setData(dataArr);
      setLoadingStatus(LOADING_STATES[2]);
      // console.log(`${collectionName} - ${LOADING_STATES[2]}`);
    } catch (e) {
      setError(e);
      setLoadingStatus(LOADING_STATES[1]);
      // console.log(`${collectionName} - ${LOADING_STATES[1]}`);
    }
    if (callbackDone) callbackDone();
  }

  useEffect(() => {
    async function getDataUseEffect(callbackDone) {
      try {
        setLoadingStatus(LOADING_STATES[0]);
        // console.log(`${collectionName} - ${LOADING_STATES[0]}***`);

        const q = query(collection(db, collectionName));
        const querySnapshot = await getDocs(q);
        let dataArr = [];
        querySnapshot.forEach((doc) => {
          dataArr.push({ ...doc.data(), id: doc.id });
        });
        setData(dataArr);
        setLoadingStatus(LOADING_STATES[2]);
        // console.log(`${collectionName} - ${LOADING_STATES[2]}***`);
      } catch (e) {
        console.log(JSON.stringify(e));
        setError(e);
        setLoadingStatus(LOADING_STATES[1]);
        // console.log(`${collectionName} - ${LOADING_STATES[1]}***`);
      }
      if (callbackDone) callbackDone();
    }
    getDataUseEffect();
  }, [collectionName]);

  function createRecord(collectionName, createObject, callbackDone) {
    async function addData() {
      try {
        createObject.createdDtm = Timestamp.now();
        createObject.updatedDtm = Timestamp.now();
        await addDoc(collection(db, collectionName), createObject).then(
          (documentRef) => {
            createObject.id = documentRef.id;
            setData(function (oriState) {
              return [createObject, ...oriState];
            });
          }
        );

        if (callbackDone) callbackDone();
      } catch (e) {
        const errorString = formatErrorString(
          e,
          `Failed create in ${collectionName}`
        );
        errorNotificationFn?.(errorString);
        if (callbackDone) callbackDone();
      }
    }
    addData();
  }

  function updateRecord(
    collectionName,
    updateObjectId,
    updateObject,
    callbackDone
  ) {
    async function updateData() {
      try {
        updateObject.updatedDtm = Timestamp.now();
        await updateDoc(
          doc(db, collectionName, updateObjectId),
          updateObject
        ).then(() => {
          setData(function (oriState) {
            const dataRecord = oriState.find(
              (rec) => rec.id === updateObjectId
            );
            // only update the fields passed in for the updateObject
            for (const [key, value] of Object.entries(updateObject)) {
              dataRecord[key] = value === undefined ? dataRecord[key] : value;
            }
            return oriState.map((rec) =>
              rec.id === updateObjectId ? dataRecord : rec
            );
          });
        });

        if (callbackDone) callbackDone();
      } catch (e) {
        const errorString = formatErrorString(
          e,
          `Failed update in ${collectionName}`
        );
        errorNotificationFn?.(errorString);
        if (callbackDone) callbackDone();
      }
    }

    if (data.find((rec) => rec.id === updateObjectId)) {
      updateData();
    } else {
      const errorString = `No data record found for id ${updateObjectId}`;
      errorNotificationFn?.(errorString);
    }
  }
  function deleteRecord(collectionName, deleteObjectId, callbackDone) {
    async function deleteData() {
      const startingData = data.map(function (rec) {
        return { ...rec };
      });
      try {
        await deleteDoc(doc(db, collectionName, deleteObjectId)).then(() => {
          setData(function (oriState) {
            return oriState.filter((rec) => deleteObjectId !== rec.id);
          });
        });

        if (callbackDone) callbackDone();
      } catch (e) {
        setData(startingData);
        const errorString = formatErrorString(
          e,
          `${deleteObjectId} delete failed`
        );
        errorNotificationFn?.(errorString);
        if (callbackDone) callbackDone();
      }
    }

    function recordExists(id) {
      const r = data.find((rec) => rec.id === id);
      return r ? true : false;
    }

    if (recordExists(deleteObjectId)) {
      deleteData(deleteObjectId);
    } else {
      const errorString = `No data record found for id ${deleteObjectId}, delete failed`;
      errorNotificationFn?.(errorString);
    }
  }

  function reFetch(callbackDone) {
    const promise = getData(() => {
      if (callbackDone) callbackDone();
    });
    return promise;
  }

  return {
    data, // returned data after loadingStatus === "success"
    loadingStatus, // "success", "errored", "loading"
    error, // error string
    reFetch, // gets the data again from the rest server
    createRecord, // creates new record at end, takes first record as parameter, second as callback function when done
    updateRecord, // update new record at end, takes single record as parameter, second as callback function when done
    deleteRecord, // takes primary key named "id"
  };
};

export default useGeneralizedCrudMethods;
