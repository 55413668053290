import { Box, Container, CssBaseline, Typography } from "@mui/material";

const NoAccess = () => {
  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 3,
            borderRadius: 4,
            padding: 3,
          }}
        >
          <Typography component="h1" variant="h5">
            Access Not Allowed
          </Typography>
          <Typography variate="h5" align="right">
            You do not have the correct credentials to access this page.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};
export default NoAccess;
