import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Paypal from "./pages/PayPal";
import RegisterRetreat from "./pages/RegisterRetreat";
import Admin from "./pages/Admin";
import SeedData from "./pages/SeedData";
import Chair from "./pages/Chair";
import { useRecoilState } from "recoil";
import { userDataAtom } from "./context/Atoms";
import Home from "./pages/Home";
import { useContext, useEffect } from "react";
import { UsersDataContext } from "./context/UsersDataContext";
import { UserAuth } from "./context/AuthContext";
import { getUserByAuth } from "./helpers/UserUtils";
import { EventsDataContext } from "./context/EventsDataContext";
import AdminTemp from "./pages/AdminTemp";
import { UserEventChoiceDataProvider } from "./context/UserEventChoiceDataContext";
import ResetPassword from "./pages/ResetPassword";

const AppRoutes = function () {
  const { usersList, reFetch: refetchUsers } = useContext(UsersDataContext);
  const { eventsList, reFetch: refetchEvents } = useContext(EventsDataContext);
  const { authUser } = UserAuth();
  const [currentUser, setCurrentUser] = useRecoilState(userDataAtom);

  useEffect(() => {
    if (usersList?.length === 0 && authUser !== null) {
      refetchUsers();
    }
    if (eventsList?.length === 0 && authUser !== null) {
      refetchEvents();
    }
    if (usersList?.length > 0 && authUser !== null && eventsList?.length > 0) {
      const userDataRec = getUserByAuth(authUser.uid, usersList);
      if (userDataRec) {
        const isChair =
          eventsList.filter((event) => event.chairperson === userDataRec.id)
            .length > 0;
        setCurrentUser({ ...userDataRec, chair: isChair });
      }
    }
  }, [
    usersList,
    authUser,
    setCurrentUser,
    eventsList,
    eventsList.length,
    refetchUsers,
    refetchEvents,
  ]);

  const defaultRouteForUser = () => {
    if (currentUser.admin) {
      return (
        <UserEventChoiceDataProvider>
          <Admin />
        </UserEventChoiceDataProvider>
      );
    } else if (
      (currentUser.chair || currentUser.coordinator) &&
      !currentUser.admin
    ) {
      return (
        <UserEventChoiceDataProvider>
          <Chair />
        </UserEventChoiceDataProvider>
      );
    } else {
      return <Home />;
    }
  };

  return (
    <Routes>
      <Route path="/signin" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      <Route
        path="/"
        element={<ProtectedRoute>{defaultRouteForUser()}</ProtectedRoute>}
      />
      <Route
        path="/user-home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/paypal/:userId/:eventId"
        element={
          <ProtectedRoute>
            <Paypal />
          </ProtectedRoute>
        }
      />
      <Route
        path="/register-retreat"
        element={
          <ProtectedRoute>
            <UserEventChoiceDataProvider>
              <RegisterRetreat />
            </UserEventChoiceDataProvider>
          </ProtectedRoute>
        }
      />
      {currentUser.chair || currentUser.coordinator || currentUser.admin ? (
        <Route
          path="/chair"
          element={
            <ProtectedRoute>
              <UserEventChoiceDataProvider>
                <Chair />
              </UserEventChoiceDataProvider>
            </ProtectedRoute>
          }
        />
      ) : null}
      {currentUser.admin ? (
        <Route>
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <UserEventChoiceDataProvider>
                  <Admin />
                </UserEventChoiceDataProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/temp"
            element={
              <UserEventChoiceDataProvider>
                <ProtectedRoute>
                  <AdminTemp />
                </ProtectedRoute>
              </UserEventChoiceDataProvider>
            }
          />
          <Route
            path="/seed-data"
            element={
              <ProtectedRoute>
                <SeedData />
              </ProtectedRoute>
            }
          />
        </Route>
      ) : null}
    </Routes>
  );
};
export default AppRoutes;
