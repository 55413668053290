import { Button, Stack } from "@mui/material";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";
import { v4 } from "uuid";

const UploadFiles = ({ refetch, filesRefPath }) => {
  const uploadFile = (imageUpload) => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `${filesRefPath}/${v4()}`);
    const metadata = {
      customMetadata: {
        friendlyName: imageUpload.name,
        uploadedBy: "Jim Parker",
      },
    };
    uploadBytes(imageRef, imageUpload, metadata).then((snapshot) => {
      refetch();
    });
  };

  return (
    <div>
      {" "}
      <Stack direction="row" spacing={2} sx={{ m: 5 }}>
        <Button variant="contained" component="label">
          <input
            type="file"
            hidden
            onChange={(event) => {
              uploadFile(event.target.files[0]);
            }}
          />
          Upload File
        </Button>
        {/* <Button variant="contained" onClick={uploadFile}>
          {" "}
          Upload
        </Button> */}
      </Stack>
    </div>
  );
};
export default UploadFiles;
