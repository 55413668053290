import React, { createContext, useMemo } from "react";
import useEventsData from "../hooks/useEventsData";

export const EventsDataContext = createContext({
  eventsList: [],
  createEvent: () => {},
  updateEvent: () => {},
  deleteEvent: () => {},
  loadingStatus: "",
});

export const EventsDataProvider = ({ children }) => {
  const {
    eventsList,
    createEvent,
    updateEvent,
    deleteEvent,
    loadingStatus,
    isPending,
    reFetch,
  } = useEventsData();

  const value = useMemo(
    () => ({
      eventsList,
      createEvent,
      updateEvent,
      deleteEvent,
      loadingStatus,
      isPending,
      reFetch,
    }),
    [
      eventsList,
      createEvent,
      updateEvent,
      deleteEvent,
      loadingStatus,
      isPending,
      reFetch,
    ]
  );

  return (
    <EventsDataContext.Provider value={value}>
      {children}
    </EventsDataContext.Provider>
  );
};
