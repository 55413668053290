import React, { useState, useEffect, useContext } from "react";
import waiverTextFile from "../assets/waiver.txt";
import {
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  CssBaseline,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { db } from "../firebase";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  addDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import RetreatDateDropdown from "../components/RetreatDateDropdown.jsx";
import Copyright from "../components/Copyright";
import SimpleSelect from "../components/SimpleSelect.jsx";
import SimpleMultiSelect from "../components/SimpleMultiSelect.jsx";
import { toShortDateHelper } from "../helpers/DateHelper";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../context/Atoms";
import { UserEventChoiceDataContext } from "../context/UserEventChoiceDataContext";
import {
  getRegisteredCount,
  isAlreadyRegistered,
} from "../helpers/EventChoiceUtils";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const RegisterRetreat = () => {
  const { userEventChoiceList = [] } = useContext(UserEventChoiceDataContext);

  const currentUser = useRecoilValue(userDataAtom);
  const navigate = useNavigate();
  const [errorList, setErrorList] = useState([]);
  const [retreatDateDropdownError, setRetreatDateDropdownError] =
    useState(false);
  const [emergencyError, setEmergencyError] = useState(false);
  const [electronicSignatureError, setElectronicSignatureError] =
    useState(false);
  const [optInError, setOptInError] = useState(false);
  const [events, setEvents] = useState([]);

  const retreatCapacity = process.env.REACT_APP__RETREAT_CAPACITY;
  const retreatTheme = process.env.REACT_APP__RETREAT_THEME;
  const [text, setText] = React.useState(null);

  const optInMessage = "I have read the above waiver.";
  const electronicSignatureMessage =
    "Please type in your name as an electronic signature indicating that you are 18 years of age or older and I agree with the terms described herein.";

  const optInLabelRed = (
    <Typography variant="h6" style={{ color: "#FA2200" }}>
      {optInMessage}
    </Typography>
  );
  const optInLabelBlack = <Typography variant="h6">{optInMessage}</Typography>;

  const electronicSignatureRed = (
    <Typography variant="h6" style={{ color: "#FA2200" }}>
      {electronicSignatureMessage}
    </Typography>
  );
  const electronicSignatureBlack = (
    <Typography variant="h6">{electronicSignatureMessage}</Typography>
  );

  const bedChoices = ["I need a bed", "I would like a bed", "No bed needed"];
  const membershipChoices = ["AA", "Alanon", "NA"];
  const serviceChoices = [
    "Transportation",
    "Chairperson",
    "Cooking",
    "Cleaning",
    "Speaking",
    "Future Retreat Service",
  ];
  const initialRetreatAssignment = {
    bedChoice: "",
    membershipChoice: [],
    serviceChoice: [],
    retreatChoice: "",
    userId: currentUser.id,
    emergencyPhoneNumber: "",
    optIn: false,
    electronicSignature: "",
  };
  const [newRetreatAssignment, setRetreatAssignment] = useState(
    initialRetreatAssignment
  );

  const waiver = () => {
    fetch(waiverTextFile)
      .then((response) => response.text())
      .then((textContent) => {
        setText(textContent);
      });
    return text || "Loading...";
  };
  const onInputRetreatChoiceChange = (value, type) => {
    setRetreatDateDropdownError(false);
    if (value !== undefined) {
      setRetreatAssignment({ ...newRetreatAssignment, [type]: value });
    }
  };
  const onInputChange = (e, type) => {
    const value = e.target.value;
    if (type === "emergency") setEmergencyError(false);
    if (type === "electronicSignature") setElectronicSignatureError(false);
    if (value !== undefined) {
      setRetreatAssignment({ ...newRetreatAssignment, [type]: value });
      // console.log(newRetreatAssignment);
    }
  };

  const onOptInChange = (value) => {
    setOptInError(false);
    if (value !== undefined) {
      setRetreatAssignment({ ...newRetreatAssignment, optIn: value });
      // console.log(newRetreatAssignment);
    }
  };

  useEffect(() => {
    const q = query(
      collection(db, "events"),
      where("startDate", ">=", Timestamp.now()),
      orderBy("startDate", "desc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let eventsArr = [];
      querySnapshot.forEach((doc) => {
        eventsArr.push({ ...doc.data(), id: doc.id });
      });
      eventsArr.sort((a, b) => {
        return a.startDate - b.startDate;
      });
      eventsArr.map((data) => {
        data.startDateNice = toShortDateHelper(data.startDate);
        data.alreadyRegistered = isAlreadyRegistered(
          data.id,
          currentUser.id,
          userEventChoiceList
        );
        return data;
      });
      setEvents(eventsArr);
    });
    return () => unsubscribe();
  }, [currentUser.id, userEventChoiceList]);

  const createUserRetreatChoice = async (e) => {
    e.preventDefault(e);
    setErrorList([]);

    if (isRetreatChoiceError()) {
      return;
    }

    try {
      let onWaitList = false;
      if (
        getRegisteredCount(
          newRetreatAssignment.retreatChoice,
          userEventChoiceList
        ) >= retreatCapacity
      ) {
        onWaitList = true;
      }

      await addDoc(collection(db, "user-retreat-choice"), {
        membershipChoice: newRetreatAssignment.membershipChoice,
        serviceChoice: newRetreatAssignment.serviceChoice,
        retreatChoice: newRetreatAssignment.retreatChoice,
        userId: currentUser.id,
        bedChoice: newRetreatAssignment.bedChoice,
        emergencyPhoneNumber: newRetreatAssignment.emergency,
        status: onWaitList ? "waiting" : "saved",
        optIn: newRetreatAssignment.optIn,
        electronicSignature: newRetreatAssignment.electronicSignature,
        updatedDtm: Timestamp.now(),
        createdDtm: Timestamp.now(),
      }).then(() => {
        navigate(
          `/paypal/${currentUser.id}/${newRetreatAssignment.retreatChoice}`
        );
      });
    } catch (e) {
      setErrorList(...errorList, e.message);
      console.log(e.message);
    }

    function isRetreatChoiceError() {
      let currentError = false;
      let errorArray = [];
      if (newRetreatAssignment.retreatChoice === "") {
        setRetreatDateDropdownError(true);
        errorArray.push("Please select a retreat date");
        currentError = true;
      }
      if (
        newRetreatAssignment.emergency === "" ||
        newRetreatAssignment.emergency === undefined
      ) {
        setEmergencyError(true);
        errorArray.push("Please enter an emergency contact number");
        currentError = true;
      }
      if (newRetreatAssignment.optIn === false) {
        setOptInError(true);
        errorArray.push("Please agree to the waiver");
        currentError = true;
      }
      if (
        newRetreatAssignment.electronicSignature === undefined ||
        newRetreatAssignment.electronicSignature.trim() === ""
      ) {
        setElectronicSignatureError(true);
        errorArray.push("Please input electronic signature");
        currentError = true;
      }
      setErrorList(errorArray);
      return currentError;
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 3,
            borderRadius: 4,
            padding: 3,
          }}
        >
          <Typography component="h1" variant="h5">
            Lake of Dreams Retreats
          </Typography>
          <Typography sx={{ mb: 3 }}>{retreatTheme}</Typography>
          <Typography variate="h5" align="right">
            Registration for {currentUser.firstName} {currentUser.lastName} (
            {currentUser.emailAddress}, {currentUser.phoneNumber})
          </Typography>
          <Box
            component="form"
            sx={{
              mt: 3,
            }}
          >
            <Grid container spacing={2}>
              {/* Retreat Dates */}
              <Grid item md={12}>
                <RetreatDateDropdown
                  error={retreatDateDropdownError}
                  events={events}
                  required
                  id="retreatChoice"
                  label="Choose Retreat"
                  name="retreatChoice"
                  value={newRetreatAssignment.retreatChoice}
                  onChange={onInputRetreatChoiceChange}
                  isNew={true}
                />
              </Grid>

              {/* emergency */}
              <Grid item xs={12} sm={6}>
                <TextField
                  error={emergencyError}
                  required
                  fullWidth
                  name="emergency"
                  label="Emergency Number"
                  type="phone"
                  id="emergency"
                  autoComplete="emergency"
                  onChange={(e, name) => {
                    onInputChange(e, "emergency");
                  }}
                />
              </Grid>
              {/* bed */}
              <Grid item xs={12} sm={6}>
                <SimpleSelect
                  required
                  name="bedChoice"
                  id="bed-choice"
                  label="Bed"
                  items={bedChoices}
                  value={newRetreatAssignment.bedChoice}
                  handleChange={onInputChange}
                />
              </Grid>
              {/* 12 step */}
              <Grid item xs={12} sm={6}>
                <SimpleMultiSelect
                  name="membershipChoice"
                  id="membership-choice"
                  label="Membership"
                  items={membershipChoices}
                  value={newRetreatAssignment.membershipChoice}
                  handleChange={onInputChange}
                />
              </Grid>
              {/* service */}
              <Grid item xs={12} sm={6}>
                <SimpleMultiSelect
                  name="serviceChoice"
                  id="service-choice"
                  label="Service"
                  items={serviceChoices}
                  value={newRetreatAssignment.serviceChoice}
                  handleChange={onInputChange}
                />
              </Grid>
            </Grid>
            <TextField
              sx={{ mt: 3, mb: 2 }}
              id="outlined-multiline-static"
              type="text"
              label="Waiver"
              variant="outlined"
              multiline={true}
              fullWidth
              maxRows={4}
              onChange={(e) => setText(e.target.value)}
              value={waiver()}
              InputProps={{
                readOnly: true,
              }}
            />
            <FormGroup sx={{ mb: 2 }}>
              <FormControlLabel
                required={true}
                name="optIn"
                control={<Checkbox />}
                onChange={(e, name) => {
                  onOptInChange(e.target.checked);
                }}
                label={optInError ? optInLabelRed : optInLabelBlack}
              />
            </FormGroup>
            {electronicSignatureError
              ? electronicSignatureRed
              : electronicSignatureBlack}
            <TextField
              error={electronicSignatureError}
              required
              fullWidth
              name="electronicSignature"
              label="Electronic Signature"
              type="electronicSignature"
              id="electronicSignature"
              // autoComplete="emergency"
              onChange={(e, name) => {
                onInputChange(e, "electronicSignature");
              }}
            />
            <Box
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <Typography
                sx={{ mt: 3 }}
                variant="h6"
                style={{ color: "#FA2200" }}
              >
                {errorList.length > 0 ? "Errors:" : ""}
              </Typography>
              <List>
                {errorList.map((errorText, i) => {
                  return (
                    <ListItem key={i} disablePadding sx={{ ml: 3 }}>
                      <ListItemText
                        primary={errorText}
                        style={{ color: "#FA2200" }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={createUserRetreatChoice}
            >
              Continue to Payment
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </div>
  );
};

export default RegisterRetreat;
