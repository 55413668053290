import * as React from "react";
import Box from "@mui/material/Box";
import { Autocomplete, TextField } from "@mui/material";

function RetreatDateDropDownNew(props) {
  const { events, label, id, value: propValue, onChange, isNew } = props;
  const currentEventIndex = events.findIndex((event) => event.id === propValue);
  const [value, setValue] = React.useState(events[currentEventIndex]);
  const [inputValue, setInputValue] = React.useState("");

  const onInputChangeLocal = (value) => {
    onChange(value, id);
  };
  return (
    <Autocomplete
      id={id}
      options={events}
      autoHighlight
      sx={{ minWidth: 330 }}
      getOptionLabel={(option) => `${option.startDateDisplay}`}
      onChange={(e, newValue) => {
        onInputChangeLocal(newValue.id);
        setValue(newValue);
      }}
      value={isNew ? undefined : value}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.startDateDisplay} ({option.retreatType}'s retreat)
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export default RetreatDateDropDownNew;
