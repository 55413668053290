import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import React from "react";
import {
  writeBatch,
  getDocs,
  query,
  collection,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import EventsFile from "../seeds/events";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../context/Atoms";
import NoAccess from "./NoAccess";

const randomChairs = (users) => {
  const chair = users[Math.floor(Math.random() * users.length)];
  let cochair = chair;
  do {
    cochair = users[Math.floor(Math.random() * users.length)];
  } while (cochair === chair);
  return { chair: chair, cochair: cochair };
};
function getRandomInt() {
  let min = Math.ceil(1000);
  let max = Math.floor(9999);
  return Math.floor(Math.random() * (max - min) + min);
}

const randomRegisters = (users, chair, cochair) => {
  let registers = [];

  do {
    let randomUserId = users[Math.floor(Math.random() * users.length)];
    if (
      randomUserId !== chair &&
      randomUserId !== cochair &&
      !registers.includes(randomUserId)
    ) {
      registers.push(randomUserId);
    }
  } while (registers.length < 28);
  return registers;
};
const randomServices = (services) => {
  let serviceChoices = [];
  do {
    let randomService = services[Math.floor(Math.random() * services.length)];
    if (!serviceChoices.includes(randomService)) {
      serviceChoices.push(randomService);
    }
  } while (serviceChoices.length < 2);
  return serviceChoices;
};
const randomItem2 = (items) => {
  return items[Math.floor(Math.random() * items.length)];
};

const SeedData = () => {
  const userData = useRecoilValue(userDataAtom);
  const DeleteData = async function () {
    if (process.env.REACT_APP__ENV.toUpperCase() === "PROD".toUpperCase()) {
      alert("You can't do that in prod!");
    } else {
      try {
        const batch = writeBatch(db);
        let counter = 0;
        const q1 = query(collection(db, "user-retreat-choice"));
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach((doc) => {
          counter = counter + 1;
          if (counter > 400) return;
          batch.delete(doc.ref);
        });

        const q2 = query(collection(db, "events"));
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit().then(() => {
          console.log("delete complete");
          return true;
        });
      } catch (e) {
        console.log(e.message);
        return false;
      }
    }
  };

  const AddData = async function () {
    if (process.env.REACT_APP__ENV.toUpperCase() === "PROD".toUpperCase()) {
      alert("You can't do that in prod!");
    } else {
      // - added to authentication store
      // - add to user table
      let userIdArr = [];

      //Fetch new Users just added
      const q1 = query(collection(db, "users"));
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach((doc) => {
        let userId = doc.id;
        userIdArr.push(userId);
      });
      //Create events
      try {
        let retreatType = "women";
        EventsFile.forEach(async (event) => {
          if (retreatType === "women") {
            retreatType = "men";
          } else {
            retreatType = "women";
          }
          let chairs = randomChairs(userIdArr);
          let newDoc = {
            startDate: new Date(event.startDate),
            retreatType: retreatType,
            chairperson: chairs.chair,
            cochairperson: chairs.cochair,
          };
          await addDoc(collection(db, "events"), newDoc);
        });

        let eventIdArr = [];

        const q2 = query(collection(db, "events"));
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
          let eventId = doc.id;
          eventIdArr.push(eventId);
        });
        const bedChoices = [
          "I need a bed",
          "I would like a bed",
          "No bed needed",
        ];
        const membershipChoices = ["AA", "Alanon", "Both"];
        const serviceChoices = [
          "Transportation",
          "Chairperson",
          "Cooking",
          "Cleaning",
          "Speaking",
          "Future Retreat Service",
        ];
        eventIdArr.forEach((eventId) => {
          let userList = randomRegisters(userIdArr);
          userList.forEach(async (id) => {
            let bedChoice = randomItem2(bedChoices);
            let membershipChoice = randomItem2(membershipChoices);
            let serviceChoice = randomServices(serviceChoices);
            let phone = `314-555-${getRandomInt()}`;
            let eventChoice = {
              membershipChoice: membershipChoice,
              serviceChoice: serviceChoice,
              retreatChoice: eventId,
              userId: id,
              bedChoice: bedChoice,
              emergencyPhoneNumber: phone,
            };
            await addDoc(collection(db, "user-retreat-choice"), eventChoice);
          });
        });
        return true;
      } catch (e) {
        console.log(e.message);
        return false;
      }
    }
  };

  if (!userData.admin) return <NoAccess />;
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Seed Data functions
          </Typography>
          <Typography paragraph={true}>
            Note: This will not add or delete users, only events and
            registrations. You'll need to be watching firebase db to see if it's
            done. No indicator of completion here.
          </Typography>
          <Button
            onClick={() => {
              let response = DeleteData();
              if (response === true) {
                alert("Data Deleted");
              }
            }}
          >
            Delete Data
          </Button>
          <Button
            onClick={() => {
              AddData();
            }}
          >
            Generate Data
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default SeedData;
