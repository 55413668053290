import React, { createContext, useMemo } from "react";
import useUserEventChoiceData from "../hooks/useUserEventChoiceData";

export const UserEventChoiceDataContext = createContext({
  userEventChoiceList: [],
  createUserEventChoice: () => {},
  updateUserEventChoice: () => {},
  deleteUserEventChoice: () => {},
  loadingStatus: "",
});

export const UserEventChoiceDataProvider = ({ children }) => {
  const {
    userEventChoiceList,
    createUserEventChoice,
    updateUserEventChoice,
    deleteUserEventChoice,
    loadingStatus,
    isPending,
    reFetch,
  } = useUserEventChoiceData();

  const value = useMemo(
    () => ({
      userEventChoiceList,
      createUserEventChoice,
      updateUserEventChoice,
      deleteUserEventChoice,
      loadingStatus,
      isPending,
      reFetch,
    }),
    [
      userEventChoiceList,
      createUserEventChoice,
      updateUserEventChoice,
      deleteUserEventChoice,
      loadingStatus,
      isPending,
      reFetch,
    ]
  );

  return (
    <UserEventChoiceDataContext.Provider value={value}>
      {children}
    </UserEventChoiceDataContext.Provider>
  );
};
