import { atom } from "recoil";

export const userDataAtom = atom({
  key: "userData", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const userChairDataAtom = atom({
  key: "userChairData",
  default: [],
});

export const usersDataAtom = atom({
  key: "usersData",
  default: [],
});
