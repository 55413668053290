import useGeneralizedCrudMethods from "./useGeneralizedCrudMethods";

const useUserEventChoiceData = () => {
  // console.log("using useUserEventChoiceData");
  const collectionName = "user-retreat-choice";
  const errorNotificationFn = (error) => {
    console.log("Error From useUserEventChoiceData", error);
  };

  const {
    data,
    error,
    loadingStatus,
    createRecord,
    updateRecord,
    deleteRecord,
    reFetch,
  } = useGeneralizedCrudMethods(collectionName, errorNotificationFn);

  function createUserEventChoice(rec, callbackDone) {
    createRecord(collectionName, rec, callbackDone);
  }

  function updateUserEventChoice(rec, callbackDone) {
    updateRecord(collectionName, rec.id, rec, callbackDone);
  }

  function deleteUserEventChoice(id, callbackDone) {
    deleteRecord(collectionName, id, callbackDone);
  }

  return {
    userEventChoiceList: data,
    userEventChoiceLoadingStatus: loadingStatus,
    error,
    createUserEventChoice,
    updateUserEventChoice,
    deleteUserEventChoice,
    reFetch,
  };
};

export default useUserEventChoiceData;
