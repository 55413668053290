import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Typography, Container, Box, Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import useFetchUserChoices from "../hooks/useFetchUserChoices";
import { v4 } from "uuid";
import { doc, Timestamp, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../context/Atoms";
import OrderInfo from "../components/OrderInfo";
import { useContext, useEffect, useState } from "react";
import { EventsDataContext } from "../context/EventsDataContext";
import { getEvent } from "../helpers/EventUtils";

export default function Paypal() {
  const { userId, eventId } = useParams();
  const { eventsList } = useContext(EventsDataContext);
  const currentUser = useRecoilValue(userDataAtom);
  const { registeredUsers } = useFetchUserChoices(eventId, userId, null);
  const [userOrder, setUserOrder] = useState({});
  const [orderError, setOrderError] = useState(null);
  const [eventData, setEventData] = useState({});
  const lodTransactionId = v4();

  const clearError = () => {
    setOrderError(null);
    setUserOrder({ ...userOrder, status: "saved" });
  };

  const getErrorMessage = () => {
    if (orderError?.message) return orderError?.message;
    if (
      registeredUsers?.length > 0 &&
      registeredUsers[0].status === "paypal-error"
    )
      return registeredUsers[0].ppErrorResponses[
        registeredUsers[0].ppErrorResponses.length - 1
      ].message;
    return orderError?.message;
  };
  const updateUserChoice = async (ppResponse) => {
    try {
      updateDoc(doc(db, "user-retreat-choice", userOrder.id), {
        status: "paid",
        lodTxId: lodTransactionId,
        ppResponse: ppResponse,
        updatedDtm: Timestamp.now(),
      }).then((data) => {
        setUserOrder({ ...userOrder, status: "paid" });
      });
    } catch (e) {
      console.log("ERROR==============================================");
      console.log(e.message);
    }
  };

  const updateUserChoicePaymentError = async (ppError) => {
    try {
      console.log("ppError:  ", ppError);

      let errObj = {
        name: ppError.name,
        message: ppError.message,
        dtm: Timestamp.now(),
      };
      updateDoc(doc(db, "user-retreat-choice", userOrder.id), {
        status: "paypal-error",
        lodTxId: lodTransactionId,
        ppErrorResponses: arrayUnion(errObj),
        updatedDtm: Timestamp.now(),
      }).then((data) => {
        setUserOrder({ ...userOrder, status: "paypal-error" });
        setOrderError(ppError);
      });
    } catch (e) {
      console.log("ERROR==============================================");
      console.log(e.message);
    }
  };

  let transactionDescription;

  useEffect(() => {
    if (registeredUsers?.length > 0) {
      setUserOrder(registeredUsers[0]);
    }
  }, [registeredUsers]);

  useEffect(() => {
    if (eventsList?.length > 0) {
      setEventData(getEvent(userOrder.retreatChoice, eventsList));
    }
  }, [eventsList, userOrder.retreatChoice]);

  if (userOrder && eventData?.startDate) {
    transactionDescription = `Retreat Payment from ${currentUser.firstName} ${currentUser.lastName} for ** ${eventData.startDateDisplay} ** tx# [${lodTransactionId}]`;
  } else {
    return null;
  }

  return (
    <div>
      <Container component="main" maxWidth="sm">
        {userOrder.status === "saved" ? (
          <Box>
            <OrderInfo
              currentUser={currentUser}
              unpaidUserChoice={userOrder}
              retreatDate={eventData.startDateDisplay}
            />
            <PayPalScriptProvider
              options={{ "client-id": process.env.REACT_APP__PAYPAL_CLIENT_ID }}
            >
              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: "65.00",
                        },
                        description: transactionDescription,
                      },
                    ],
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then((details) => {
                    updateUserChoice(details);
                  });
                }}
                onError={(err) => {
                  updateUserChoicePaymentError(err);
                }}
              />
            </PayPalScriptProvider>
          </Box>
        ) : null}
        {userOrder.status === "paid" ? (
          <Box
            sx={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 3,
              borderRadius: 4,
              padding: 3,
            }}
          >
            <Typography variant="h5">
              {`Registration for  ${currentUser.firstName} ${currentUser.lastName} is complete for retreat on '${eventData.startDateDisplay}'`}
            </Typography>
            <Typography variant="h5" sx={{ marginTop: 10 }}>
              View retreat information in{" "}
              <Link to="/user-home">My Retreat</Link>
            </Typography>
          </Box>
        ) : null}
        {userOrder.status === "waiting" ? (
          <Box
            sx={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 3,
              borderRadius: 4,
              padding: 3,
            }}
          >
            <Typography variant="h5">
              {` ${currentUser.firstName} ${currentUser.lastName} has been added to the waiting list for retreat on '${eventData.startDateDisplay}'`}
            </Typography>
            <Typography variant="h5" sx={{ marginTop: 10 }}>
              View retreat information in{" "}
              <Link to="/user-home">My Retreat</Link>
            </Typography>
          </Box>
        ) : null}
        {userOrder.status === "paypal-error" ? (
          <Box
            sx={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 3,
              borderRadius: 4,
              padding: 3,
            }}
          >
            <Typography variant="h5" style={{ color: "red" }}>
              {` The Paypal transaction failed. Paypal error message: '${getErrorMessage()}'`}
            </Typography>
            <Typography variant="h5" sx={{ marginTop: 5, marginBottom: 3 }}>
              Please click below to try your transaction again. If you continue
              to have issues, please contact the retreat coordinator.
            </Typography>
            <Button onClick={clearError}>Go Back to payment page</Button>
          </Box>
        ) : null}
      </Container>
    </div>
  );
}
