const EventsFile = [
  {
    startDate: "2023-02-10T12:00:00Z",
  },
  {
    startDate: "2023-03-01T09:00:00Z",
  },
  {
    startDate: "2023-04-15T14:00:00Z",
  },
  {
    startDate: "2023-05-20T16:00:00Z",
  },
  {
    startDate: "2023-04-15T14:00:00Z",
  },
  {
    startDate: "2023-05-20T16:00:00Z",
  },
  {
    startDate: "2023-06-30T10:00:00Z",
  },
  {
    startDate: "2023-07-05T10:00:00Z",
  },
  {
    startDate: "2023-07-15T14:00:00Z",
  },
  {
    startDate: "2023-07-20T16:00:00Z",
  },
  {
    startDate: "2023-07-30T10:00:00Z",
  },
  {
    startDate: "2022-02-10T12:00:00Z",
  },
  {
    startDate: "2024-03-01T09:00:00Z",
  },
  {
    startDate: "2022-04-15T14:00:00Z",
  },
  {
    startDate: "2022-05-20T16:00:00Z",
  },
  {
    startDate: "2022-06-30T10:00:00Z",
  },
  {
    startDate: "2022-07-05T10:00:00Z",
  },
  {
    startDate: "2022-07-15T14:00:00Z",
  },
];
export default EventsFile;
