import { Typography, Box, Container } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import HomeRetreatView from "../components/HomeRetreatView";
import { userDataAtom } from "../context/Atoms";
import { EventsDataContext } from "../context/EventsDataContext";
import { UsersDataContext } from "../context/UsersDataContext";
import { getEvent } from "../helpers/EventUtils";
import { getUserById } from "../helpers/UserUtils";
import useFetchUserChoices from "../hooks/useFetchUserChoices";

const Home = function () {
  const currentUser = useRecoilValue(userDataAtom);
  const { registeredUsers } = useFetchUserChoices(null, currentUser.id, null);
  const { eventsList } = useContext(EventsDataContext);
  const { usersList } = useContext(UsersDataContext);
  const [choiceData, setChoiceData] = useState([]);
  const retreatTheme = process.env.REACT_APP__RETREAT_THEME;

  useEffect(() => {
    if (
      registeredUsers?.length > 0 &&
      eventsList?.length > 0 &&
      usersList?.length > 0
    ) {
      let arr = registeredUsers.map((choice) => {
        let currentEvent = getEvent(choice.retreatChoice, eventsList);
        let currentChair = getUserById(currentEvent.chairperson, usersList);

        return {
          userId: choice.userId,
          chairName: `${currentChair.firstName} ${currentChair.lastName} `,
          retreatDate: currentEvent.startDate,
          retreatChoiceDate: currentEvent.startDateDisplay,
          status: choice.status,
          chairPhone: currentChair.phoneNumber,
          id: choice.id,
          retreatChoice: choice.retreatChoice,
        };
      });
      const filteredEvents = arr.filter((rec) => {
        let date = Date.now();
        const numOfDays = 30;
        const nanoMultiplier = 24 * 60 * 60 * 1000;
        let newDate = date - numOfDays * nanoMultiplier;

        return rec.retreatDate.toDate() >= newDate;
      });
      setChoiceData(filteredEvents);
    }
  }, [currentUser, registeredUsers, eventsList, usersList]);

  let title = "My Retreat";
  if (choiceData?.length > 0) title = "My Retreats";

  return (
    <div>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 3,
            borderRadius: 4,
            padding: 3,
          }}
        >
          <Typography>{retreatTheme}</Typography>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          {choiceData?.length === 0 ? (
            <Typography variant="h6" sx={{ mt: 3 }}>
              You are not signed up for any retreats. Click here{" "}
              <Link to="/register-retreat">to register</Link>.
            </Typography>
          ) : (
            choiceData.map((choice) => {
              return <HomeRetreatView choice={choice} key={choice.id} />;
            })
          )}
        </Box>
      </Container>
    </div>
  );
};
export default Home;
