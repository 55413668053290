import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";

const useFetchUserChoices = (selectedItem, userId, users) => {
  const [registeredUsers, setRegisteredUsers] = useState([]);
  let q;

  //refactor this shit
  if (!userId && selectedItem) {
    q = query(
      collection(db, "user-retreat-choice"),
      where("retreatChoice", "==", selectedItem)
    );
  } else if (userId && !selectedItem) {
    q = query(
      collection(db, "user-retreat-choice"),
      where("userId", "==", userId)
    );
  } else if (userId && selectedItem) {
    q = query(
      collection(db, "user-retreat-choice"),
      where("retreatChoice", "==", selectedItem),
      where("userId", "==", userId)
    );
  }

  useEffect(() => {
    const fetch = async () => {
      // console.log("fetching user-choices now (NOT VIA CRUD)");
      if (selectedItem || userId) {
        const querySnapshot = await getDocs(q);
        let dataArr = [];
        querySnapshot.forEach((doc) => {
          let registered = doc.data();

          dataArr.push({ ...registered, id: doc.id });
          if (users) {
            dataArr.map((data) => {
              let cp = users.find((x) => x.id === data.userId);
              if (cp) {
                data.userDisplayName = `${cp.firstName} ${cp.lastName}`;
                data.phone = cp.phoneNumber;
                data.email = cp.emailAddress;
              }
              return data;
            });
          }
        });
        setRegisteredUsers(dataArr);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, userId]);

  return { registeredUsers };
};
export default useFetchUserChoices;
