import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Button,
  Switch,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useState, Fragment } from "react";
import { useRecoilValue } from "recoil";
import RetreatDateDropDownNew from "../components/RetreatDateDropdownNew";
import UpdateDownloadFiles from "../components/UpdateDownloadFiles";
import UploadFiles from "../components/UploadFiles";
import { userDataAtom } from "../context/Atoms";
import { EventsDataContext } from "../context/EventsDataContext";
import { UsersDataContext } from "../context/UsersDataContext";
import useFetchFileUrls from "../hooks/useFetchFileUrls";
import NoAccess from "./NoAccess";
import {
  toShortDateHelper,
  getStartDateDisplay,
  formatTsToYYYYMMDDTime,
} from "../helpers/DateHelper";
import { EventBusy, Upgrade } from "@mui/icons-material";
import YesNoDialog from "../components/YesNoDialog";
import { UserEventChoiceDataContext } from "../context/UserEventChoiceDataContext";
import {
  getChoicesByEvent,
  getChoicesByEvents,
} from "../helpers/EventChoiceUtils";
import { Timestamp } from "@firebase/firestore";
import getCsvData from "../helpers/CsvHelper";

const Chair = function () {
  const { eventsList } = useContext(EventsDataContext);
  const { usersList } = useContext(UsersDataContext);
  const { userEventChoiceList = [], updateUserEventChoice } = useContext(
    UserEventChoiceDataContext
  );
  const [chairmanData, setChairmanData] = useState([]);
  const currentUser = useRecoilValue(userDataAtom);
  const [selectedItem, setSelectedItem] = useState("");
  const retreatCapacity = process.env.REACT_APP__RETREAT_CAPACITY;
  const [isLessThanCapacity, setIsLessThanCapacity] = useState(true);

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelDialogMsg, setCancelDialogMsg] = useState("");
  const [itemToCancel, setItemToCancel] = useState({});

  const [offWaitListDialogOpen, setOffWaitListDialogOpen] = useState(false);
  const [offWaitListDialogMsg, setOffWaitListDialogMsg] = useState("");
  const [itemToOffWaitList, setItemToOffWaitList] = useState({});

  const [showAllEventChoices, setShowAllEventChoices] = useState(false);

  const toggleShowAll = (event) => {
    setShowAllEventChoices(event.target.checked);
  };

  const [countOfRegistered, setCountOfRegistered] = useState(0);
  const [countOfUnpaid, setCountOfUnpaid] = useState(0);
  const [countOfWaiting, setCountOfWaiting] = useState(0);

  const NUM_OF_DAYS_BACK_TO_SHOW =
    process.env.REACT_APP__DAYS_HIST_SHOWN_EVENTS;
  const filesListRefPath = `retreat-files/${selectedItem}`;
  const { fileUrls, refetch: refetchFileUrls } =
    useFetchFileUrls(filesListRefPath);
  const [userChoices, setUserChoices] = useState([]);
  const [coordinatorUserChoices, setCoordinatorUserChoices] = useState([]);

  const getExportedFilename = () => {
    const selectedEvent = eventsList.filter((event) => {
      return event.id === selectedItem;
    });
    const fileTitle = selectedEvent[0].startDateDisplay
      .replace(/\s+/g, "-")
      .replace(/,/g, "");
    return `${fileTitle}.csv`;
  };

  const formatJsonArray = (jsonObjectArray) => {
    const selectedEvent = eventsList.filter((event) => {
      return event.id === selectedItem;
    });

    return jsonObjectArray.map((jsonObject) => {
      return {
        retreatChoiceDate: selectedEvent[0].startDateDisplay,
        status: jsonObject.status,
        userDisplayName: jsonObject.userDisplayName,
        phone: jsonObject.phone,
        email: jsonObject.email,
        bedChoice: jsonObject.bedChoice,
        membershipChoice: jsonObject.membershipChoice?.join(", "),
        serviceChoice: jsonObject.serviceChoice?.join(", "),
        emergencyPhoneNumber: jsonObject.emergencyPhoneNumber,
        optIn: jsonObject.optIn,
        electronicSignature: jsonObject.electronicSignature,
        createdDtmDisplay: new Date(jsonObject.createdDtm).toLocaleDateString(
          "en-us",
          {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }
        ),
        updatedDtmDisplay: new Date(jsonObject.updatedDtm).toLocaleDateString(
          "en-us",
          {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }
        ),
      };
    });
  };

  const downloadChairpersonCsv = (e, jsonObjectArray) => {
    const newJsonArray = formatJsonArray(jsonObjectArray);
    const exportedFilename = getExportedFilename();
    downloadCsv(newJsonArray, exportedFilename);
  };

  const downloadCoordinatorCsv = () => {
    downloadCsv(
      coordinatorUserChoices,
      `coordinator-${currentUser.coordinator}.csv`
    );
  };

  const downloadCsv = (newJsonArray, exportedFilename) => {
    const csv = getCsvData(newJsonArray);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const link = document.createElement("a");
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = exportedFilename;
        link.text = `Download ${exportedFilename}`;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.style.visibility = "hidden";
        link.click();
        link.remove();
        URL.revokeObjectURL(link.href);
      }
    }
  };

  //dialog handling
  const showCancelDialog = (choice) => {
    let msg = `Are you sure you want to cancel ${choice.userDisplayName} for this retreat?`;
    if (choice.status === "paid") {
      msg = `${msg}  *** Note that this person has already paid.  You may have to arrange for their refund.`;
    }
    setCancelDialogMsg(msg);
    setItemToCancel(choice);
    setCancelDialogOpen(true);
  };

  const onCloseCancelDialog = (result) => {
    if (result === true && itemToCancel !== null) {
      cancelRegistration(itemToCancel);
    }
    setCancelDialogOpen(false);
  };

  const cancelRegistration = (choice) => {
    try {
      updateUserEventChoice({
        id: choice.id,
        status: "canceled",
        updatedDtm: Timestamp.now(),
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const showOffWaitingListDialog = (choice) => {
    let msg = `Are you sure you want to add ${choice.userDisplayName} to this retreat and take off waiting list?`;

    setOffWaitListDialogMsg(msg);
    setItemToOffWaitList(choice);
    setOffWaitListDialogOpen(true);
  };

  const onCloseOffWaitingListDialog = (result) => {
    if (result === true && itemToOffWaitList !== null) {
      moveOffWaitingList(itemToOffWaitList);
    }
    setOffWaitListDialogOpen(false);
  };

  const moveOffWaitingList = (choice) => {
    try {
      updateUserEventChoice({
        id: choice.id,
        status: "saved",
        updatedDtm: Timestamp.now(),
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  //Grid setup
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 60,
      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "canceled" ? null : (
              <Tooltip title="Cancel Registration">
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    showCancelDialog(params.row);
                  }}
                >
                  <EventBusy />
                </IconButton>
              </Tooltip>
            )}
            {params.row.status !== "waiting" || !isLessThanCapacity ? null : (
              <Tooltip title="Move to Registered">
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    showOffWaitingListDialog(params.row);
                  }}
                >
                  <Upgrade />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    { field: "status", headerName: "Status", width: 70 },
    { field: "userDisplayName", headerName: "Name", width: 140 },
    { field: "phone", headerName: "Phone", width: 120 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "bedChoice", headerName: "Bed Choice", width: 90 },
    {
      field: "emergencyPhoneNumber",
      headerName: "Emer Phone",
      width: 120,
    },
    { field: "membershipChoice", headerName: "Member", width: 70 },

    {
      field: "createdDtm",
      headerName: "Created",
      width: 130,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
      },
    },
    { field: "serviceChoice", headerName: "Service Offered", width: 620 },
  ];

  const handleChange = (value) => {
    setSelectedItem(value);
    setCountOfRegistered(0);
    setCountOfUnpaid(0);
    setCountOfWaiting(0);
  };

  //events list matching currentUser is chairperson or not
  useEffect(() => {
    eventsList.sort((a, b) => {
      return a.startDate - b.startDate;
    });
    const eventsArr = eventsList.filter((event) => {
      if (event.chairperson === currentUser.id) return event;
      if (currentUser.coordinator === "all") return event;
      if (currentUser.coordinator === event.retreatType) return event;
      return null;
    });
    eventsArr.map((data) => {
      data.startDateDisplay = toShortDateHelper(data.startDate);
      return data;
    });

    const filteredEvents = eventsArr.filter((rec) => {
      let date = Date.now();
      const nanoMultiplier = 24 * 60 * 60 * 1000;
      let newDate = date - NUM_OF_DAYS_BACK_TO_SHOW * nanoMultiplier;
      return rec.startDate.toDate() >= newDate;
    });
    if (filteredEvents.length === 1) {
      setSelectedItem(filteredEvents[0].id);
    }
    setChairmanData(filteredEvents);
  }, [
    currentUser.id,
    eventsList,
    NUM_OF_DAYS_BACK_TO_SHOW,
    currentUser.coordinator,
  ]);

  //user choices list matching selected event
  useEffect(() => {
    let arr;
    if (userEventChoiceList?.length > 0) {
      arr = getChoicesByEvent(selectedItem, userEventChoiceList);
      arr.map((data) => {
        let cp = usersList.find((x) => x.id === data.userId);
        if (cp) {
          data.userDisplayName = `${cp.firstName} ${cp.lastName}`;
          data.phone = cp.phoneNumber;
          data.email = cp.emailAddress;
        }

        if (data.createdDtm.seconds !== undefined && data.createdDtm) {
          data.createdDtm = new Date(
            data.createdDtm.seconds * 1000
          ).toISOString();
        }
        if (data.updatedDtm.seconds !== undefined && data.updatedDtm) {
          data.updatedDtm = new Date(
            data.updatedDtm.seconds * 1000
          ).toISOString();
        }
        return data;
      });

      let eventsToShow = arr;
      if (!showAllEventChoices) {
        eventsToShow = arr.filter((event) => event.status !== "canceled");
      }
      setUserChoices(eventsToShow);
    }

    if (arr?.length > 0) {
      const registered = arr.filter((choice) => {
        return choice.status !== "canceled" && choice.status !== "waiting";
      }).length;
      const unpaid = arr.filter((choice) => {
        return choice.status === "saved" || choice.status === "paypal-error";
      }).length;
      const waiting = arr.filter((choice) => {
        return choice.status === "waiting";
      }).length;

      setCountOfRegistered(registered);
      setCountOfUnpaid(unpaid);
      setCountOfWaiting(waiting);
      setIsLessThanCapacity(retreatCapacity - registered > 0);
    }
  }, [
    retreatCapacity,
    selectedItem,
    userEventChoiceList,
    usersList,
    showAllEventChoices,
  ]);

  //user choices list for coordinator view
  useEffect(() => {
    if (userEventChoiceList?.length > 0) {
      const filteredEvents = eventsList.filter((rec) => {
        let date = Date.now();
        const numOfDays = NUM_OF_DAYS_BACK_TO_SHOW;
        const nanoMultiplier = 24 * 60 * 60 * 1000;
        let newDate = date - numOfDays * nanoMultiplier;
        return rec.startDate.toDate() >= newDate;
      });

      const coordinatorChoices = getChoicesByEvents(
        currentUser.coordinator,
        filteredEvents,
        userEventChoiceList
      );

      const newJsonArray = coordinatorChoices.map((jsonObject) => {
        let cp = usersList.find((x) => x.id === jsonObject.userId);
        if (cp) {
          jsonObject.userDisplayName = `${cp.firstName} ${cp.lastName}`;
          jsonObject.phone = cp.phoneNumber;
          jsonObject.email = cp.emailAddress;
        }
        if (
          jsonObject.createdDtm.seconds !== undefined &&
          jsonObject.createdDtm
        ) {
          jsonObject.createdDtm = new Date(
            jsonObject.createdDtm.seconds * 1000
          ).toISOString();
        }
        if (
          jsonObject.updatedDtm.seconds !== undefined &&
          jsonObject.updatedDtm
        ) {
          jsonObject.updatedDtm = new Date(
            jsonObject.updatedDtm.seconds * 1000
          ).toISOString();
        }

        return {
          retreatChoiceDate: getStartDateDisplay(
            jsonObject.retreatChoice,
            eventsList
          ),
          status: jsonObject.status,
          userDisplayName: jsonObject.userDisplayName,
          phone: jsonObject.phone,
          email: jsonObject.email,
          bedChoice: jsonObject.bedChoice,
          membershipChoice: jsonObject.membershipChoice?.join(", "),
          serviceChoice: jsonObject.serviceChoice?.join(", "),
          emergencyPhoneNumber: jsonObject.emergencyPhoneNumber,
          optIn: jsonObject.optIn,
          electronicSignature: jsonObject.electronicSignature,
          createdDtmDisplay: formatTsToYYYYMMDDTime(jsonObject.createdDtm),
          updatedDtmDisplay: formatTsToYYYYMMDDTime(jsonObject.updatedDtm),
        };
      });

      setCoordinatorUserChoices(newJsonArray);
    }
  }, [
    userEventChoiceList,
    eventsList,
    usersList,
    currentUser.coordinator,
    NUM_OF_DAYS_BACK_TO_SHOW,
  ]);

  if (!currentUser.admin && !currentUser.chair && !currentUser.coordinator)
    return <NoAccess />;

  return (
    <div>
      <Container component="main">
        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 3,
            borderRadius: 4,
            padding: 3,
            width: "1",
          }}
        >
          <Typography component="h1" variant="h5">
            Chairperson Page
          </Typography>
          <Typography variate="h5" align="right">
            Select retreat below to see more detail.
          </Typography>
          <Box
            component="form"
            sx={{
              mt: 3,
            }}
          >
            {chairmanData.length > 1 ? (
              <RetreatDateDropDownNew
                events={chairmanData}
                id="retreatChoice"
                label="Choose Retreat"
                name="retreatChoice"
                value={chairmanData[0]}
                onChange={handleChange}
                isNew={true}
              />
            ) : chairmanData.length === 1 ? (
              <Typography variate="h5" align="right">
                {`Retreat Date: ${chairmanData[0].startDateDisplay}`}
              </Typography>
            ) : null}
            {coordinatorUserChoices.length > 0 ? (
              <Button
                sx={{
                  mt: 3,
                }}
                variant="text"
                onClick={(e) => {
                  downloadCoordinatorCsv();
                }}
              >
                {currentUser.coordinator
                  ? `Download Coordinator Data (${currentUser.coordinator})`
                  : null}
              </Button>
            ) : null}
          </Box>
        </Box>
        {selectedItem ? (
          <Fragment>
            <Container sx={{ mt: 5 }}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Grid container>
                  <Grid item md={6}>
                    <UploadFiles
                      filesRefPath={filesListRefPath}
                      refetch={refetchFileUrls}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <UpdateDownloadFiles
                      fileUrls={fileUrls}
                      refetch={refetchFileUrls}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Container>
            <Container sx={{ mt: 5 }}>
              {/* <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "row",
                  width: "1",
                }}
              > */}
              {userChoices.length > 0 ? (
                <Button
                  variant="text"
                  onClick={(e) => {
                    downloadChairpersonCsv(e, userChoices);
                  }}
                >
                  {selectedItem ? `Download ${getExportedFilename()}` : null}
                </Button>
              ) : null}
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="space-between"
              >
                <div>
                  <Switch
                    checked={showAllEventChoices}
                    onChange={toggleShowAll}
                  />
                  <label>{"Show All"}</label>
                </div>
                <Typography
                  variant="body1"
                  align="left"
                  paddingLeft={2}
                  paddingBottom={2}
                >
                  <b> Sign Up Stats:</b> <br />
                  {countOfRegistered} of {retreatCapacity} registered (
                  {countOfUnpaid} unpaid)
                  <br />
                  {countOfWaiting} on waiting list
                </Typography>
                {/* Rest of your component */}
              </Grid>
              <Grid container sx={{ width: "1", display: "inline" }}>
                <Grid item xs zeroMinWidth sx={{ width: "1" }}>
                  <div
                    style={{
                      display: "flex",
                      height: 650,
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <DataGrid
                        getRowHeight={() => "auto"}
                        rows={userChoices}
                        columns={columns}
                        pageSize={40}
                        rowsPerPageOptions={[40]}
                        hideFooterSelectedRowCount={true}
                        flexDirection="column"
                        // sx={{
                        //   "& .MuiDataGrid-renderingZone": {
                        //     maxHeight: "none !important",
                        //   },
                        //   "& .MuiDataGrid-cellContent": {
                        //     lineHeight: "unset !important",
                        //     maxHeight: "none !important",
                        //     whiteSpace: "normal",
                        //   },
                        //   "& .MuiDataGrid-row": {
                        //     maxHeight: "none !important",
                        //   },
                        // }}
                        // siz
                        // width={100}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              {/* </Paper> */}
            </Container>
          </Fragment>
        ) : null}
        <YesNoDialog
          open={cancelDialogOpen}
          title={cancelDialogMsg}
          onClose={onCloseCancelDialog}
        />
        <YesNoDialog
          open={offWaitListDialogOpen}
          title={offWaitListDialogMsg}
          onClose={onCloseOffWaitingListDialog}
        />
      </Container>
    </div>
  );
};
export default Chair;
