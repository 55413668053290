import React, { useContext, useEffect, useState } from "react";
import { Timestamp } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Box,
  Container,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EventModal from "../components/EventModal";
import YesNoDialog from "../components/YesNoDialog";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../context/Atoms";
import NoAccess from "./NoAccess";
import { toShortDateHelper } from "../helpers/DateHelper";
import { UsersDataContext } from "../context/UsersDataContext";
import { EventsDataContext } from "../context/EventsDataContext";
import { UserEventChoiceDataContext } from "../context/UserEventChoiceDataContext";
import { getRegisteredCount } from "../helpers/EventChoiceUtils";

const Admin = () => {
  const NUM_OF_DAYS_BACK_TO_SHOW =
    process.env.REACT_APP__DAYS_HIST_SHOWN_EVENTS;
  //State
  const { userEventChoiceList = [] } = useContext(UserEventChoiceDataContext);
  const { usersList } = useContext(UsersDataContext);
  const { eventsList, createEvent, updateEvent, deleteEvent } =
    useContext(EventsDataContext);
  const currentUser = useRecoilValue(userDataAtom);

  const [hydratedEvents, setHydratedEvents] = useState([]);
  const [newEvent, setNewEvent] = useState("");
  const [open, setOpen] = useState(false);
  const [isNewEvent, setIsNewEvent] = useState("");
  const [ddOpen, setDdOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);

  const retreatCapacity = process.env.REACT_APP__RETREAT_CAPACITY;

  // GridTable set up
  const columns = [
    {
      field: "startDateDisplay",
      headerName: "Retreat Date",
      width: 130,
      sortable: false,
    },
    { field: "retreatType", headerName: "Type", width: 80, sortable: false },
    {
      field: "chairpersonDisplayName",
      headerName: "Chair",
      width: 160,
      sortable: false,
    },
    {
      field: "cochairpersonDisplayName",
      headerName: "CoChair",
      width: 160,
      sortable: false,
    },
    {
      field: "registeredCountString",
      headerName: "Registered",
      width: 100,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={(e) => showUpdateEventModal(e, params.row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {params.row.registeredCount > 0 ? null : (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={(e) => showDeleteDialog(e, params.row)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  //Event Modal Handlers
  const showCreateEventModal = () => {
    setNewEvent({
      startDate: "",
      retreatType: "",
      chairperson: "",
      cochairperson: "",
    });
    setOpen(true);
    setIsNewEvent(true);
  };

  const showUpdateEventModal = (e, params, event, details) => {
    setNewEvent(params);
    setOpen(true);
  };

  const closeEventModal = () => {
    setOpen(false);
    setIsNewEvent(false);
  };

  const showDeleteDialog = (e, params) => {
    setDeleteMsg(
      `Are you sure you want to delete the ${JSON.stringify(
        toShortDateHelper(params.startDate)
      )} event?`
    );
    setItemToDelete(params.id);
    setDdOpen(true);
  };

  const onCloseDeleteDialog = (result) => {
    if (result === true && itemToDelete !== null) {
      removeEvent();
    }
    setDdOpen(false);
  };

  //Change handlers
  const onInputChange = (value, type) => {
    if (value !== undefined) {
      setNewEvent({ ...newEvent, [type]: value });
    }
  };

  const onDateChange = (newValue) => {
    let newDate = new Date(newValue);
    setNewEvent({
      ...newEvent,
      startDate: Timestamp.fromDate(newDate),
      startDateDisplay: newValue,
    });
  };

  //Event create
  const saveNewEvent = async (e) => {
    e.preventDefault(e);
    if (newEvent.startDate === "") {
      alert("Please enter a date for the retreat");
      return;
    }
    createEvent({
      startDate: newEvent.startDate,
      retreatType: newEvent.retreatType,
      chairperson: newEvent.chairperson,
      cochairperson: newEvent.cochairperson,
    });
    closeEventModal();
  };

  //Event update
  const saveUpdatedEvent = async (e) => {
    e.preventDefault(e);
    try {
      updateEvent(newEvent.id, {
        chairperson: newEvent.chairperson,
        cochairperson: newEvent.cochairperson,
        retreatType: newEvent.retreatType,
        startDate: newEvent.startDate,
      });
      closeEventModal();
    } catch (e) {
      console.log(e.message);
    }
  };

  //Event delete
  const removeEvent = async () => {
    deleteEvent(itemToDelete);
  };

  useEffect(() => {
    eventsList.sort((a, b) => {
      return a.startDate - b.startDate;
    });
    eventsList.map((data) => {
      let cp = usersList.find((x) => x.id === data.chairperson);
      if (cp) {
        data.chairpersonDisplayName = `${cp.firstName} ${cp.lastName}`;
      }
      let cocp = usersList.find((x) => x.id === data.cochairperson);
      if (cocp) {
        data.cochairpersonDisplayName = `${cocp.firstName} ${cocp.lastName}`;
      }
      data.startDateDisplay = toShortDateHelper(data.startDate);
      data.registeredCount = 0;
      if (userEventChoiceList.length > 0) {
        data.registeredCount = getRegisteredCount(data.id, userEventChoiceList);
      }
      data.registeredCountString = `${data.registeredCount} / ${retreatCapacity}`;

      return data;
    });

    const filteredEvents = eventsList.filter((rec) => {
      let date = Date.now();
      const nanoMultiplier = 24 * 60 * 60 * 1000;
      let newDate = date - NUM_OF_DAYS_BACK_TO_SHOW * nanoMultiplier;
      return rec.startDate.toDate() >= newDate;
    });
    setHydratedEvents(filteredEvents);
  }, [
    usersList,
    usersList.length,
    eventsList,
    eventsList.length,
    retreatCapacity,
    userEventChoiceList,
    userEventChoiceList.length,
    NUM_OF_DAYS_BACK_TO_SHOW,
  ]);

  if (!currentUser.admin) return <NoAccess />;
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Box
          component="main"
          backgroundColor="#d7dbd8"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Button onClick={showCreateEventModal}>Add new Event</Button>
                  <div style={{ display: "flex", height: 650 }}>
                    <div style={{ flexGrow: 1 }}>
                      <DataGrid
                        rows={hydratedEvents}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideFooterSelectedRowCount={true}
                        siz
                      />
                    </div>
                  </div>
                  <EventModal
                    event={newEvent}
                    users={usersList}
                    closeEventModal={closeEventModal}
                    onInputChange={onInputChange}
                    onDateChange={onDateChange}
                    createEvent={saveNewEvent}
                    updateEvent={saveUpdatedEvent}
                    open={open}
                    isNewEvent={isNewEvent}
                  />
                  <YesNoDialog
                    open={ddOpen}
                    title={deleteMsg}
                    onClose={onCloseDeleteDialog}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default Admin;
