import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import Copyright from "../components/Copyright";
import {
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  CssBaseline,
  Avatar,
  Paper,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

const ResetPassword = () => {
  const [, setError] = useState("");
  const [currentState, setCurrentState] = useState({ status: "beforeSubmit" });
  const { sendPasswordReset } = UserAuth();
  const [emailAddress, setEmailAddress] = useState("");

  const handleOnChange = (event) => {
    setEmailAddress(event.currentTarget.value);
  };

  const handleSubmit = async (event) => {
    setError("");
    try {
      await sendPasswordReset(emailAddress);
      setCurrentState({ status: "afterSubmit" });
      // console.log(`currentState: ${JSON.stringify(currentState)}`);
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };
  // console.log(JSON.stringify(currentState));
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://lakeofdreams.org/img/Lodi-MR.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Lake of Dreams Retreats
            </Typography>
            {currentState.status === "beforeSubmit" ? (
              <div>
                <Typography variate="h5" align="center">
                  Enter email address to reset password.
                </Typography>

                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleOnChange}
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                  >
                    Reset Password
                  </Button>

                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </div>
            ) : (
              <div>
                <Typography variant="h4" align="center" sx={{ mt: 3 }}>
                  Reset email sent!
                </Typography>
                <Link to="/signin">Login</Link>
                <br />
                <Link
                  onClick={() => {
                    setCurrentState({ status: "beforeSubmit" });
                  }}
                >
                  Or try Again?
                </Link>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ResetPassword;
