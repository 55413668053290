import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function SimpleSelect(props) {
  const { items, id, label, handleChange, value, name } = props;
  const onChange = (e) => {
    handleChange(e, name);
  };

  const labelId = `${id}-label`;
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          id={id}
          label={label}
          value={value}
          onChange={onChange}
        >
          {items.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
