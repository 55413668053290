import React from "react";
import Carousel from "react-material-ui-carousel";
import Image from "mui-image";

const SignInCarousel = function () {
  const items = [
    {
      image: "https://lakeofdreams.org/img/Retreat.png",
    },
    {
      image: "https://lakeofdreams.org/img/Lodi-MR.png",
    },
    {
      image: "https://lakeofdreams.org/img/Ladies_Retreat.JPG",
    },
  ];

  return (
    <Carousel interval={10000}>
      {items.map((item, i) => (
        <Image key={`key-${i + 1}`} src={item.image} />
      ))}
    </Carousel>
  );
};

export default SignInCarousel;
