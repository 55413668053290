export const toShortDateHelper = (date) => {
  return date.toDate().toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const getStartDateDisplay = (id, eventsList) => {
  const event = eventsList.find((event) => event.id === id);
  return formatTsToYYYYMMDD(event.startDate);
};

export const formatTsToYYYYMMDD = (dateTS) => {
  const date = dateTS.toDate();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 since months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
export const formatTsToYYYYMMDDTime = (dateTS) => {
  // console.log(dateTS);
  // const date = dateTS.toDate();
  // const year = date.getFullYear();
  // const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 since months are zero-based
  // const day = String(date.getDate()).padStart(2, "0");
  // const hours = String(date.getHours()).padStart(2, "0");
  // const minutes = String(date.getMinutes()).padStart(2, "0");
  // const seconds = String(date.getSeconds()).padStart(2, "0");

  // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return dateTS.substring(0, dateTS.length - 5);
};
