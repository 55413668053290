import { IconButton, Stack, Tooltip } from "@mui/material";
import { deleteObject } from "firebase/storage";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import YesNoDialog from "./YesNoDialog";

const UpdateDownloadFiles = ({ fileUrls, refetch }) => {
  const [ddOpen, setDdOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);

  const showDeleteDialog = (e, urlObj) => {
    setDeleteMsg(
      `Are you sure you want to delete the file ${JSON.stringify(urlObj.name)}?`
    );
    setItemToDelete(urlObj);
    setDdOpen(true);
  };

  const onCloseDeleteDialog = (result) => {
    if (result === true && itemToDelete !== null) {
      deleteObject(itemToDelete.itemRef)
        .then(() => {
          refetch();
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
    setDdOpen(false);
  };

  return (
    <div>
      <Stack direction="column" spacing={1} sx={{ mb: 1 }}>
        {fileUrls.map((urlObj, i) => {
          return (
            <div key={i}>
              <a
                alt=""
                target="_blank"
                rel="noreferrer noopener"
                href={urlObj.url}
              >
                {urlObj.name || `file${i}`}
              </a>
              <Tooltip title="Delete" placement="right">
                <IconButton
                  aria-label="delete"
                  onClick={(e) => showDeleteDialog(e, urlObj)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        })}
      </Stack>
      <YesNoDialog
        open={ddOpen}
        title={deleteMsg}
        onClose={onCloseDeleteDialog}
      />
    </div>
  );
};
export default UpdateDownloadFiles;
