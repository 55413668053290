import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function PersonDropdown(props) {
  const users = props.users;
  const label = props.label;
  const id = props.userId;
  const onInputChange = props.onChange;
  const currentUserIndex = users.findIndex((u) => u.id === props.value);
  const [value, setValue] = React.useState(users[currentUserIndex]);
  const [inputValue, setInputValue] = React.useState("");

  const onInputChangeLocal = (value) => {
    onInputChange(value, props.id);
  };
  return (
    <Autocomplete
      id={id}
      sx={{ width: 300 }}
      options={users}
      autoHighlight
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      onChange={(e, newValue) => {
        onInputChangeLocal(newValue.id);
        setValue(newValue);
      }}
      value={props.isNewEvent ? undefined : value}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.firstName} {option.lastName} ({option.emailAddress})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export default PersonDropdown;
