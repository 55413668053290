export const getRegisteredCount = function (eventId, retreatChoiceList) {
  if (eventId) {
    const registered = retreatChoiceList.filter(
      (rec) => rec.retreatChoice === eventId && rec.status !== "canceled"
    );
    return registered.length;
  }
};

export const isAlreadyRegistered = function (
  eventId,
  userId,
  retreatChoiceList
) {
  const registered = retreatChoiceList.filter((rec) => {
    return rec.retreatChoice === eventId && rec.userId === userId;
  });
  return registered.length > 0;
};

export const getChoicesByEvent = function (eventId, choices) {
  return choices.filter((choice) => {
    return choice.retreatChoice === eventId;
  });
};

export const getChoicesByEvents = function (retreatType, events, choices) {
  return choices.filter((choice) => {
    if (retreatType === "all") {
      return events.some((event) => event.id === choice.retreatChoice);
    } else {
      return events.some(
        (event) =>
          event.id === choice.retreatChoice && event.retreatType === retreatType
      );
    }
  });
};
