import { Avatar, IconButton, Tooltip } from "@mui/material";

function UserAvatar(props) {
  const { setAnchorElUser, userData } = props;

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    let iconText = "";
    if (name.split(" ").length > 1) {
      iconText = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    } else {
      iconText = `${name.split(" ")[0][0]}`;
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: iconText,
    };
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const userToolTip = () => {
    if (userData) {
      return `${userData.firstName} ${userData.lastName} (${userData.emailAddress} ${userData.id})`;
    }
  };

  return (
    <Tooltip title={userToolTip()}>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar
          {...stringAvatar(
            `${userData && userData.firstName} ${userData && userData.lastName}`
          )}
        />
      </IconButton>
    </Tooltip>
  );
}

export default UserAvatar;
