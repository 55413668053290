import React from "react";
import { AuthContextProvider } from "./context/AuthContext";
import { Fab } from "@mui/material";
import { RecoilRoot } from "recoil";
import LodAppBar from "./components/LodAppBar";
import AppRoutes from "./AppRoutes";
import { UsersDataProvider } from "./context/UsersDataContext";
import { EventsDataProvider } from "./context/EventsDataContext";

const EnvFab = () => {
  return (
    <Fab
      sx={{
        position: "absolute",
        top: 60,
        right: "1%",
        color: "yellow",
        backgroundColor: "#dd2c00",
        borderColor: "black",
      }}
      variant="extended"
      href={process.env.REACT_APP__PR_URL}
    >
      {`ENV: ${process.env.REACT_APP__ENV}`}
    </Fab>
  );
};

function App() {
  return (
    <React.StrictMode>
      <UsersDataProvider>
        {process.env.REACT_APP__ENV !== "PROD" ? <EnvFab /> : ""}

        <AuthContextProvider>
          <EventsDataProvider>
            <RecoilRoot>
              <LodAppBar />
              <AppRoutes />
            </RecoilRoot>
          </EventsDataProvider>
        </AuthContextProvider>
      </UsersDataProvider>
    </React.StrictMode>
  );
}

export default App;
