import { Button, Divider, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ViewDownloadFiles from "./ViewDownloadFiles";

function HomeRetreatView({ choice }) {
  const navigate = useNavigate();

  const payNow = (userId, eventId) => {
    navigate(`/paypal/${userId}/${eventId}`);
  };
  const decodeStatus = (status) => {
    let decoded = "None";
    switch (status) {
      case "paid":
        decoded = "Paid";
        break;
      case "saved":
        decoded = "Not Paid";
        break;
      case "paypal-error":
        decoded = "Not Paid - PayPal Error";
        break;
      case "canceled":
        decoded = "Registration Canceled";
        break;
      case "waiting":
        decoded = "On Waiting List";
        break;
      default:
        break;
    }
    return decoded;
  };

  return (
    <div>
      <Container component="main" maxWidth="md" sx={{ mt: 4 }}>
        <div key={choice.id}>
          <Typography variant="h5" align="center" color="blue">
            {choice.retreatChoiceDate}
          </Typography>
          <Divider />
          <Typography sx={{ mt: 2 }}>
            STATUS: {decodeStatus(choice.status)}
            {choice.status === "saved" || choice.status === "paypal-error" ? (
              <Button
                sx={{ ml: 10 }}
                variant="contained"
                size="small"
                onClick={() => {
                  payNow(choice.userId, choice.retreatChoice);
                }}
              >
                PayNow
              </Button>
            ) : null}{" "}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Chairperson: {choice.chairName}
            {"  "}({choice.chairPhone})
          </Typography>

          <ViewDownloadFiles eventId={choice.retreatChoice} />
        </div>
      </Container>
    </div>
  );
}
export default HomeRetreatView;
