import { List, ListItem, ListItemText, Stack, Typography } from "@mui/material";

function OrderInfo({ currentUser, unpaidUserChoice, retreatDate }) {
  return (
    <Stack>
      <Typography variant="h6" margin={2}>
        {`Order Details for: ${currentUser.firstName} ${currentUser.lastName}`}
      </Typography>
      <Typography variant="p" margin={2}>
        <List>
          <ListItem>
            <ListItemText
              primary={`Name: ${currentUser.firstName} ${currentUser.lastName}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Retreat Date: ${retreatDate}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Email: ${currentUser.emailAddress}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Phone: ${currentUser.phoneNumber}`} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Bed Request: ${unpaidUserChoice.bedChoice}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Emergency #: ${unpaidUserChoice.emergencyPhoneNumber}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Service Offer: ${unpaidUserChoice.serviceChoice}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Opted In to terms in waiver: ${unpaidUserChoice.optIn}`}
            />
          </ListItem>
        </List>
      </Typography>
    </Stack>
  );
}
export default OrderInfo;
