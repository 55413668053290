import { Typography } from "@mui/material";
import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../context/Atoms";
import { EventsDataContext } from "../context/EventsDataContext";
import { UserEventChoiceDataContext } from "../context/UserEventChoiceDataContext";
import { toShortDateHelper } from "../helpers/DateHelper";

const AdminTemp = function () {
  const currentUser = useRecoilValue(userDataAtom);
  const { userEventChoiceList = [] } = useContext(UserEventChoiceDataContext);
  const { eventsList = [] } = useContext(EventsDataContext);

  return (
    <div>
      <Typography component="h6">My retreats</Typography>
      <ul>
        {userEventChoiceList.map((item) => {
          if (item.userId === currentUser.id) {
            return (
              <li key={item.id + item.userId}>
                docId: {item.id} ---- userId: {item.userId} :: STATUS: _
                {item.status}_
              </li>
            );
          }
          return null;
        })}
      </ul>
      <Typography component="h6">Retreats I chair</Typography>
      <ul>
        {eventsList.map((item) => {
          if (item.chairperson === currentUser.id) {
            return (
              <li key={item.id + item.userId}>
                {JSON.stringify(item)} {toShortDateHelper(item.startDate)}
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};
export default AdminTemp;
