import { Button, Dialog, DialogTitle, Stack } from "@mui/material";

const YesNoDialog = (props) => {
  const { onClose, open, title } = props;

  const handleYes = () => {
    onClose(true);
  };

  const handleNo = () => {
    onClose(false);
  };

  return (
    <Dialog onClose={handleNo} open={open}>
      <DialogTitle sx={{ mt: 2 }}>{title}</DialogTitle>
      <div>
        <Stack
          sx={{ mt: 2, mb: 4 }}
          justifyContent={"center"}
          spacing={2}
          direction="row"
        >
          <Button onClick={handleYes} variant="contained">
            Yes
          </Button>
          <Button onClick={handleNo} variant="contained">
            No
          </Button>
        </Stack>
      </div>
    </Dialog>
  );
};
export default YesNoDialog;
