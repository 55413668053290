import React from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PersonDropdown from "./PersonDropdown";

const EventModal = ({
  event,
  closeEventModal,
  onInputChange,
  onDateChange,
  createEvent,
  updateEvent,
  open,
  isNewEvent,
  users,
}) => {
  const onInputChangeRetreatTypeLocal = (e, value) => {
    onInputChange(value, "retreatType");
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={closeEventModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box sx={modalStyle}>
            <Typography component="h1" variant="h5">
              {isNewEvent ? "Add new retreat event" : "Update retreat event"}
            </Typography>
            <Box
              component="form"
              autoComplete="new-password"
              noValidate
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      required
                      label="Start Date *"
                      id="startdate"
                      name="startdate"
                      value={event.startDateDisplay}
                      onChange={(newValue) => {
                        onDateChange(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      disablePast={true}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel id="retreat-type-radio-group-label">
                    Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="retreat-type-radio-group-label"
                    name="retreatType"
                    value={event.retreatType}
                    onChange={onInputChangeRetreatTypeLocal}
                  >
                    <FormControlLabel
                      value="women"
                      control={<Radio />}
                      label="women's retreat"
                    />
                    <FormControlLabel
                      value="men"
                      control={<Radio />}
                      label="men's retreat"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <PersonDropdown
                    users={users}
                    required
                    fullWidth
                    id="chairperson"
                    label="Choose Chairperson"
                    name="chairperson"
                    value={event.chairperson}
                    onChange={onInputChange}
                    isNewEvent={isNewEvent}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PersonDropdown
                    users={users}
                    required
                    fullWidth
                    id="cochairperson"
                    label="Choose Co-Chairperson"
                    name="cochairperson"
                    value={event.cochairperson}
                    onChange={onInputChange}
                    isNewEvent={isNewEvent}
                  />
                </Grid>
              </Grid>
              <Button
                type="button"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={closeEventModal}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ ml: 3, mt: 3, mb: 2 }}
                onClick={isNewEvent ? createEvent : updateEvent}
              >
                {isNewEvent ? "Create Event" : "Update Event"}
              </Button>
            </Box>
          </Box>
        </Container>
      </Modal>
    </div>
  );
};

export default EventModal;
