import { getDownloadURL, getMetadata, listAll, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { storage } from "../firebase";

const useFetchFileUrls = (filesListRefPath) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const filesListRef = ref(storage, filesListRefPath);
  const fetchFiles = async () => {
    try {
      const { items } = await listAll(filesListRef);

      const filesPromise = items.map(async (itemRef) => {
        const [url, metadata] = await Promise.all([
          getDownloadURL(itemRef),
          getMetadata(itemRef),
        ]);
        let newObj = {
          name:
            metadata.customMetadata?.friendlyName || "data_issue_placeholder",
          url,
          itemRef,
        };
        return newObj;
      });

      const files = await Promise.all(filesPromise);

      setFiles(files);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesListRefPath]);

  const refetch = () => {
    fetchFiles();
  };

  return { fileUrls: files, loading, refetch };
};
export default useFetchFileUrls;
