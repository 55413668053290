import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import WaterIcon from "@mui/icons-material/Water";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../context/Atoms";
import UserAvatar from "./UserAvatar";
import { useState } from "react";

function LodAppBar() {
  const [currentUser, setCurrentUser] = useRecoilState(userDataAtom);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { logout } = UserAuth();
  const navigate = useNavigate();

  // console.log("Rendering AppBar");

  // Avatar menu
  let avatarMenu = ["Logout"];
  if (process.env.REACT_APP__ENV === "LocalEnv" && currentUser.admin)
    avatarMenu.push("RefreshData");

  let menuItems = [];

  if (currentUser.admin) {
    menuItems = [
      { label: "AdminPage", path: "/admin" },
      { label: "Chairperson", path: "/chair" },
      { label: "Register", path: "/register-retreat" },
      { label: "My Retreat", path: "/user-home" },
    ];
  } else if (currentUser.chair || currentUser.coordinator) {
    menuItems = [
      { label: "Chairperson", path: "/chair" },
      { label: "Register", path: "/register-retreat" },
      { label: "My Retreat", path: "/user-home" },
    ];
  } else if (
    !currentUser.chair &&
    !currentUser.coordinator &&
    !currentUser.admin
  ) {
    menuItems = [
      { label: "Register", path: "/register-retreat" },
      { label: "My Retreat", path: "/user-home" },
    ];
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    setAnchorElNav(null);
    if (e.target.firstChild !== null) {
      navigate(e.target.dataset.path);
    }
  };

  const handleCloseUserMenu = async (e) => {
    if (e.target.firstChild !== null) {
      // console.log(`handleCloseUserMenu===>${e.target.firstChild.nodeValue}`);
      if (e.target.firstChild.nodeValue === "Profile") {
        setAnchorElUser(null);
        throw new Error("User Profile is not implemented");
      }
      if (e.target.firstChild.nodeValue === "Logout") {
        setAnchorElUser(null);
        await logout();
        setCurrentUser({});
        navigate("/");
      }
      if (e.target.firstChild.nodeValue === "RefreshData") {
        setAnchorElUser(null);
        navigate("/seed-data");
      }
    }
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <WaterIcon sx={{ display: { xs: "none", md: "flex" }, mr: 2 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 4,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LODI Retreats
          </Typography>
          {currentUser.userId !== undefined ? (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {menuItems.map((page) => (
                  <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" data-path={page.path}>
                      {page.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : null}

          <WaterIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Lake Of Dreams
          </Typography>

          {currentUser.userId !== undefined ? (
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {menuItems.map((page) => (
                <Button
                  key={page.label}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                  data-path={page.path}
                >
                  {page.label}
                </Button>
              ))}
            </Box>
          ) : null}
          {currentUser.userId !== undefined ? (
            <Box sx={{ flexGrow: 0 }}>
              <UserAvatar
                setAnchorElUser={setAnchorElUser}
                userData={currentUser}
              />{" "}
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {avatarMenu.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : null}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default LodAppBar;
