import { Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import useFetchFileUrls from "../hooks/useFetchFileUrls";

const ViewDownloadFiles = ({ eventId }) => {
  const filesListRefPath = `retreat-files/${eventId}`;
  const { fileUrls } = useFetchFileUrls(filesListRefPath);

  const filesTitle = "Information to download (click on link to view):";

  return (
    <div>
      {fileUrls?.length === 0 ? null : (
        <Fragment>
          <Typography sx={{ mt: 1 }}>{filesTitle}</Typography>
          <Stack direction="column" spacing={1} sx={{ mb: 1 }}>
            {fileUrls.map((urlObj, i) => {
              return (
                <div key={i}>
                  <a
                    alt=""
                    target="_blank"
                    rel="noreferrer noopener"
                    href={urlObj.url}
                  >
                    {urlObj.name || `file${i}`}
                  </a>
                </div>
              );
            })}
          </Stack>
        </Fragment>
      )}
    </div>
  );
};
export default ViewDownloadFiles;
