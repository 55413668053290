import React, { createContext, useMemo } from "react";
import useUsersData from "../hooks/useUsersData";

export const UsersDataContext = createContext({
  usersList: [],
  createUser: () => {},
  updateUser: () => {},
  deleteUser: () => {},
  loadingStatus: "",
});

export const UsersDataProvider = ({ children }) => {
  const {
    usersList,
    createUser,
    updateUser,
    deleteUser,
    loadingStatus,
    isPending,
    reFetch,
  } = useUsersData();

  const value = useMemo(
    () => ({
      usersList,
      createUser,
      updateUser,
      deleteUser,
      loadingStatus,
      isPending,
      reFetch,
    }),
    [
      createUser,
      deleteUser,
      isPending,
      loadingStatus,
      reFetch,
      updateUser,
      usersList,
    ]
  );

  return (
    <UsersDataContext.Provider value={value}>
      {children}
    </UsersDataContext.Provider>
  );
};
